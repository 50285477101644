<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>首页</el-breadcrumb-item>
		</el-breadcrumb>
		<h1 class="welcome">您好，{{username}}</h1>
		<el-card v-loading="loading">
			<p class="title">个人信息</p>
			<el-descriptions v-if="Object.keys(ownerInfo).length > 0" class="margin-top" :column="2"
				border :labelStyle="{'width': '100px'}">
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-person"></i>
						房东姓名
					</template>
					{{ownerInfo.username}}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-mobile"></i>
						手机号
					</template>
					{{ownerInfo.mobile}}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-email"></i>
						邮件地址
					</template>
					{{ownerInfo.email}}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-bank"></i>
						银行名称
					</template>
					{{ownerInfo.bank.name}}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						<i class="iconfont icon-account"></i>
						银行账户
					</template>
					{{ownerInfo.bank.account}}
				</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<el-card v-loading="loading">
			<p class="title">房产信息</p>
			<el-descriptions v-if="Object.keys(ownerInfo).length > 0" class="margin-top" :column="1"
				border :labelStyle="{'width': '120px'}">
				<el-descriptions-item v-for="(property, index) in ownerInfo.properties" :key="property.id">
					<template slot="label">
						<i class="iconfont icon-dizhi"></i>
						{{'房产地址' + (index + 1)}}
					</template>
					<div class="property-slot">
						<router-link :to="`/income/property/${property.id}`">{{property.address}}
						</router-link>
						<el-button type="primary" plain size="small" @click="routeToDetail(property.id)">
							查看详情</el-button>
					</div>
				</el-descriptions-item>
			</el-descriptions>
			<div class="link-wrap">
				<el-button type="primary" icon="el-icon-edit" @click="jumpToContact">点击联系Gigi修改信息
				</el-button>
			</div>
		</el-card>
	</div>
</template>

<script>
export default {
	name: 'Welcome',
	data() {
		return {
			ownerInfo: {},
			queryInfo: {},
			username: window.sessionStorage.getItem('username')
		};
	},
	created() {
		this.getOwnerInfo();
	},
	methods: {
		async getOwnerInfo() {
			this.loading = true;
			this.queryInfo.email = window.sessionStorage.getItem('email');
			const {data: res} = await this.$http.get('client/info', {params: this.queryInfo});
			if (res.meta.status !== 200) return this.$message.error('获取用户信息列表失败！');
			this.ownerInfo = res.data;
			this.loading = false;
		},
		routeToDetail(id) {
			this.$router.push(`/income/property/${id}`);
		},
		jumpToContact() {
			this.$router.push('/contact');
		}
	},
}
</script>

<style lang="less" scoped>
.welcome {
	text-align: center;
	margin: 40px auto;
}
.el-card {
	.title {
		font-size: 22px;
		line-height: 1.5;
		font-weight: 600;
		margin-bottom: 20px;
	}
	.link-wrap {
		margin-top: 30px;
		text-align: center;
		.el-link {
			font-size: 14px;
			line-height: 20px;
		}
	}
}
.property-slot {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
a {
	text-decoration: none;
	color: #606266;
}
</style>